import {
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Container,
  Text,
  Button,
  Center,
} from "@chakra-ui/react";
import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSignIn } from "../../hooks/useSignIn";
import "./SignIn.scss";

interface ISignIn {}

const SignIn: FC<ISignIn> = () => {
  const [nickname, setNickName] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { fetchSignIn, error, isLoading } = useSignIn();
  const handleSubmit = async () => {
    const resp = await fetchSignIn({ nickname, password });
    
    if (resp) {
        console.log('resp', resp);
      navigate("/");
    }
  };

  const handleKeydown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13 || event.key === 'Enter') {
      handleSubmit();
    }
    }

  return (
    <Container
      maxW="md"
      centerContent
      minH={"100vh"}
      display={"flex"}
      justifyContent={"center"}
    >
      <FormControl className="container__form">
        <Text fontSize={"2xl"} textAlign={"center"}>
          Accedi
        </Text>
        <FormLabel htmlFor="text">Nickname</FormLabel>
        <Input
          id="text"
          type="text"
          value={nickname}
          onChange={({ target: { value } }) => setNickName(value)}
          onKeyDown={handleKeydown}

        />
        {/* <FormHelperText>We'll never share your nickname.</FormHelperText> */}
        <FormLabel paddingTop={"2rem"} htmlFor="password">
          Password
        </FormLabel>
        <Input
          id="password"
          type="password"
          value={password}
          onChange={({ target: { value } }) => setPassword(value)}
          onKeyDown={handleKeydown}
        />
        <FormHelperText textAlign={"center"} color={"red"}>
          {error}
        </FormHelperText>
        <Center height="50px" paddingTop={"2rem"}>
          <Button
            isLoading={isLoading}
            textAlign={"center"}
            colorScheme="blue"
            onClick={handleSubmit}
          >
            Accedi
          </Button>
        </Center>
      </FormControl>
    </Container>
  );
};

export default SignIn;
