import { CheckIcon, SmallCloseIcon } from "@chakra-ui/icons";
import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  Text,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ListItem,
  OrderedList,
  List,
  ListIcon,
} from "@chakra-ui/react";
import { getDownloadURL, ref } from "firebase/storage";
import React, {
  FC,
  MouseEventHandler,
  useEffect,
  useMemo,
  useState,
} from "react";
import { myStorage } from "../../App";
import { usePlayList } from "../../hooks/usePlaylist";
import useYouTubeDownload from "../../hooks/useYoutubeDownload";
import { HtmlDecode } from "../../utils/htmldecoder";
import "./ModalDownloadAll.scss";
const downloadjs = require("downloadjs");
interface IModalDownloadAll {
  onOpen: MouseEventHandler<HTMLButtonElement>;
  onClose: () => void;
  isOpen: boolean;
}

const ModalDownloadAll: FC<IModalDownloadAll> = ({
  onOpen,
  isOpen,
  onClose,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [statusDownload, setStatusDownload] = useState<{
    number: number;
    titles: string[];
  }>({ number: 0, titles: [] });
  const { playlist } = usePlayList();

  const downloadAllSong = () => {
    if (!playlist) return;
    setIsLoading(true);
    //    const storageRef = ref(myStorage, `mp3/${videoId}.mp3`);
    playlist.forEach(({ videoId, title }) => {
      getDownloadURL(ref(myStorage, `mp3/${videoId}.mp3`))
        .then((url) => {
          const xhr = new XMLHttpRequest();
          xhr.responseType = "blob";
          xhr.onload = (event) => {
            setStatusDownload((prev) => ({
              number: prev.number + 1,
              titles: [...prev.titles, HtmlDecode(title) ?? ""],
            }));
            console.log("download");

            const blob = xhr.response;
            downloadjs(blob, `${HtmlDecode(title)}.mp3`, "audio/mp3");
          };
          xhr.open("GET", url);
          xhr.send();
        })
        .catch((error) => {
          console.log("error", error);
        });
    });

    setIsLoading(false);
    setStatusDownload((prev) => ({ ...prev, number: prev.number + 1 }));
  };

  const sortedTitle = useMemo(() => {
    if (!playlist) return [];

    return playlist.map(({ title }) => HtmlDecode(title)).sort();
  }, [playlist]);

  const isDownloadedSong = (song: string) =>   statusDownload.titles.some((title) => song === title);
  


  return (
    <>
      <Modal blockScrollOnMount isOpen={isOpen} onClose={onClose} isCentered scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Modal Title</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text textAlign={"center"} fontWeight="bold" mb="1rem">
              {`Stai per scaricare ${playlist?.length}`}
            </Text>
            <Text textAlign={"center"} fontWeight="bold" mb="1rem">
              {`${statusDownload.number ?? ""}/${playlist?.length}`}
            </Text>
            <List spacing={3}>
              {sortedTitle &&
                sortedTitle.map((title) => {
                  return <ListItem key={title} isTruncated> 
                    <ListIcon as={isDownloadedSong(title!) ? CheckIcon : SmallCloseIcon} color={`${isDownloadedSong(title!) ? 'green' : 'red'}.400`} />
                    {title}
                  </ListItem>;
                })}
            </List>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button  onClick={downloadAllSong} variant="ghost" color={"yellow.200"}>
              Download All
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalDownloadAll;
