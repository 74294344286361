import { CloseButton, Progress, Text } from "@chakra-ui/react";
import React, { FC, useEffect, useMemo, useState } from "react";
import cx from "classnames";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "./CustomToast.scss";
import useYouTubeDatabase from "../../hooks/useYoutubeDatabase";
import { IRespYoutubeLibraryMapped } from "../../models/RespYoutubeLibrary";

interface ICustomToast {}

const CustomToast: FC<ICustomToast> = () => {
  const [songFiltered, setSongFiltered] = useState<IRespYoutubeLibraryMapped[]>(
    []
  );
  const { songs } = useYouTubeDatabase();

  useEffect(() => {
    setSongFiltered(
      songs.filter(
        ({ status }) => status === "converting" || status === "processing"
      )
    );
  }, [songs]);


  return (
    <>
      <TransitionGroup className="todo-list">
        {songFiltered.map((item, index) => {
          return (
            <CSSTransition key={item.id} timeout={500} classNames="item">
              <div
                key={item.videoId}
                className={`container__custom__toast`}
                style={{ bottom: `${70 * index + 10}px` }}
              >
                {/* <CloseButton className="close__button__toast" size={"sm"} /> */}
                <Text
                  color={"black"}
                  isTruncated
                  maxW={"3xs"}
                  fontWeight={"bold"}
                >
                  {item.title}
                </Text>
                {item.status === "converting" ? (
                  <Progress size="xs" isIndeterminate />
                ) : (
                  <Progress
                    colorScheme={"gray"}
                    height={"2"}
                    hasStripe
                    isAnimated
                    aria-label="ciao"
                    value={item.percentual!}
                  />
                )}
              </div>
            </CSSTransition>
          );
        })}
      </TransitionGroup>
    </>
  );
};

export default CustomToast;
