import {
  Container,
  Box,
  Heading,
  Text,
  Stack,
  Skeleton,
} from "@chakra-ui/react";
import React, { FC, useEffect, useState } from "react";
import { myStorage } from "../../App";
import useYouTubeSearch from "../../hooks/useYouTubeSearch";
import Card from "../../components/Card/Card";
import Search from "../../components/Search/Search";
import { ref, listAll } from "firebase/storage";


import "./Main.scss";
import CustomToast from "../../components/CustomToast/CustomToast";
import { useDispatch, useSelector } from "react-redux";
import { RootReducer } from "../../redux";
import { setTermSearchInput } from "../../redux/reducers/termSearchInput";
import { useYoututbeAddFavorite } from "../../hooks/useYoututbeAddFavorite";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import useYouTubeDatabase from "../../hooks/useYoutubeDatabase";
import { usePlayList } from "../../hooks/usePlaylist";
const dloader = require("yt-mp3-dl");

interface IMain {}

const Main: FC<IMain> = () => {
  const {term: input} = useSelector(({termSearchInput}: RootReducer) => termSearchInput);
  const dispatch = useDispatch();
  const {addFavorite, getFavorites} = useYoututbeAddFavorite();
  const {fetchPlayList} = usePlayList();
  const [error, setError] = useState(false);
  const [isSearchLoading, setSearchLoading] = useState(false);
  const navigate = useNavigate();
  const {getStorageItem} = useLocalStorage();
  const [data, fetchSearch] = useYouTubeSearch();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setTermSearchInput(event.target.value));
  };
  const handleSearch = async () => {
    setSearchLoading(true);
    await fetchSearch(input);
    setSearchLoading(false);
  };
  useEffect(() => {
    
    console.log('asd',getStorageItem("credentials"));
    
    !getStorageItem("credentials") && navigate("/signin")
     input && handleSearch();
    
    // const listRef = ref(myStorage, "mp3");

    // // Find all the prefixes and items.
    // listAll(listRef)
    //   .then((res) => {
    //     res.items.forEach((itemRef) => {
    //       console.log("item", itemRef);
    //     });
    //   })
    //   .catch((error) => {
    //     console.log("error", error);
    //   });
  
      getFavorites();
      fetchPlayList();
  }, []);

 

  return (
    <>
    <CustomToast ></CustomToast>
      <Container maxW="container.md">
        <Box textAlign="center" fontSize="xl">
          <Box mt="5" mb="5">
            <Heading className="special-elite-font" size="2xl">
              YouTube Downloader
            </Heading>
            <Text>Convert and download Youtube videos in MP3 free</Text>
          </Box>
          <Search
            handleChange={handleChange}
            handleSearch={handleSearch}
            error={error}
            input={input}
            isLoading={isSearchLoading}
          />
          <div>
            {data &&
              data.map((item) => {
                return <div key={item.id.videoId} > <Card key={item.id.videoId} video={item} /></div>;
              })}

            {isSearchLoading && (
              <>
                <Stack>
                  {Array.from({ length: 6 }, (_, i) => i).map((i) => (
                    <Skeleton key={i} borderRadius={"10px"} height="500px" />
                  ))}
                </Stack>
              </>
            )}
          </div>
        </Box>
      </Container>
 
    </>
  );
};

export default Main;
