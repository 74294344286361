import axios from "axios";
import { pathApi } from "../enviroument";
import { useLocalStorage } from "./useLocalStorage"

export const useYoututbeAddFavorite = () => {
    const {getCredentials} = useLocalStorage();
    const addFavorite = async(body: Record<'videoId' | 'title', string>) => {
        try{
        if(!getCredentials()) return;
        const {token, nickname } = getCredentials()!; 
        
        console.log("🚀 ~ file: useYoututbeAddFavorite.ts ~ line 10 ~ addFavorite ~ nickname", nickname)
        
       const {data: resp} = await axios.post(`${pathApi}/youtube/addfavorite`, body, {headers: {
        authorization: `Bearer ${token}`,
        nickname
       }})
       return resp
    }catch(e: any) {
        console.log(e);
        return undefined;
        
    }   
    }
    const getFavorites = async() => {
        try{
        if(!getCredentials()) return;
        const {token, nickname } = getCredentials()!; 
        
        
       const {data: resp} = await axios.get(`${pathApi}/youtube/favorites`, {headers: {
        authorization: `Bearer ${token}`,
        nickname
       }});
       console.log('favorites', resp);
       
       return resp
    }catch(e: any) {
        console.log(e);
        return undefined;
        
    }   
    }
    return {addFavorite, getFavorites};
}