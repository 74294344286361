import { Box, Flex, Input, Button } from '@chakra-ui/react';
import { error } from 'console';
import React, { FC } from 'react';
import "./Search.scss";

interface ISearch {
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleSearch: () => void;
    error: boolean;
    input: string;
    isLoading: boolean;
}

const Search: FC<ISearch> = (props) => {
    const { handleChange, handleSearch, error, input, isLoading } = props;

    const handleKeydown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.keyCode === 13 || event.key === 'Enter') {
          handleSearch();
        }
        }

return (
    <Box mt="2" mb="2">
    <Flex gridGap="2">
      <Input
        isInvalid={error}
        placeholder="Search or paste video Youtube title"
        onChange={handleChange}
        value={input}
        onKeyDown={handleKeydown}
      />
      <Button
        onClick={handleSearch}
        isLoading={isLoading}
        loadingText="Searching..."
      >
        Search
      </Button>
    </Flex>
  </Box>
)
};

export default Search;