import { Container, Box, Heading } from "@chakra-ui/react";
import React, { FC, useEffect, useState } from "react";
import { socketConnection } from "../../App";
import Card from "../../components/Card/Card";
import CardLibrary from "../../components/CardLibrary/CardLibrary";
import CustomToast from "../../components/CustomToast/CustomToast";
import Search from "../../components/Search/Search";
import useYouTubeDatabase from "../../hooks/useYoutubeDatabase";
import "./Library.scss";

interface ILibrary {}

const Library: FC<ILibrary> = () => {
  const {
    fetchVideos,
    filteredData,
  } = useYouTubeDatabase();
  const [input, setInput] = useState("");
  const [error, setError] = useState(false);
  const [isSearchLoading, setSearchLoading] = useState(false);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value);
    setSearchLoading(true);
    await filteredData(event.target.value);
    setSearchLoading(false);
  };
  const handleSearch = async () => {
    setSearchLoading(true);
    await filteredData(input);
    setSearchLoading(false);
  };
  useEffect(() => {
    fetchVideos();
  }, []);

  return (<>
      <CustomToast ></CustomToast>
    <Container maxW="container.md">
      <Box textAlign="center" fontSize="xl">
      <Heading marginBottom={10} marginTop={5} className="special-elite-font" size="2xl">
          Library
        </Heading>
        <Search
          handleChange={handleChange}
          handleSearch={handleSearch}
          error={error}
          input={input}
          isLoading={isSearchLoading}
        />
        <div>
          {filteredData(input) &&
            filteredData(input).map((item) => {
              return <CardLibrary key={item.videoId} video={item} />;
            })}
        </div>
      </Box>
    </Container>
    </>
  );
};

export default Library;
