import { Container, Box, Heading, Button, useDisclosure } from "@chakra-ui/react";
import { error } from "console";
import React, { FC, useEffect, useState } from "react";
import CardLibrary from "../../components/CardLibrary/CardLibrary";
import CardPlayList from "../../components/CardPlayList/CardPlayList";
import CustomToast from "../../components/CustomToast/CustomToast";
import Search from "../../components/Search/Search";
import { usePlayList } from "../../hooks/usePlaylist";
import { ref, getDownloadURL } from "firebase/storage";
import "./PlayList.scss";
import { myStorage } from "../../App";
import { HtmlDecode } from "../../utils/htmldecoder";
import ModalDownloadAll from "../../components/ModalDownloadAll/ModalDownloadAll";
const downloadjs  = require("downloadjs");

interface IPlayList {}

const PlayList: FC<IPlayList> = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
  const [input, setInput] = useState("");
  const [error, setError] = useState(false);
  const [isSearchLoading, setSearchLoading] = useState(false);
  const [percentualDownload, setPercentualDownload] = useState();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { filterPlayList, fetchPlayList, filteredPlayList, playlist } = usePlayList();


  const downloadAllSong = () => {
onOpen();
  }
  useEffect(() => {
    fetchPlayList();
  }, []);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value);
    setSearchLoading(true);
    filterPlayList(event.target.value);
    setSearchLoading(false);
  };

  const handleSearch = async () => {
    setSearchLoading(true);
     filterPlayList(input);
    setSearchLoading(false);
  };

  return (<>
      <CustomToast ></CustomToast>
        <ModalDownloadAll isOpen={isOpen} onClose={onClose} onOpen={onOpen}></ModalDownloadAll>
      <Box textAlign="center" fontSize="xl">
    <Container maxW="container.xl">
        <Heading marginBottom={10} marginTop={5} className="special-elite-font" size="2xl">
          PlayList
        </Heading>
        <Button onClick={downloadAllSong}>donwload all</Button>
        <Search
          handleChange={handleChange}
          handleSearch={handleSearch}
          error={error}
          input={input}
          isLoading={isSearchLoading}
        />
            </Container>
        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", marginTop: 40, marginBottom: 40}}>
          {filteredPlayList &&
            filteredPlayList.map((item) => {
              return <CardPlayList key={item.videoId} video={item} />;
            })}
        </div>
      </Box>
</>
  );
};

export default PlayList;
