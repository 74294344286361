import { IRespSignIn } from "../models/RespSignIn";

type KeyStorage = {
    credentials: IRespSignIn
};

export const useLocalStorage = () => {



 const setStorageItem = async<K extends keyof KeyStorage>(key: K, value: KeyStorage[K]) => {
    await localStorage.setItem(key, JSON.stringify(value));
}

 const removeStorageItem = <K extends keyof KeyStorage>(key: K) => {
    localStorage.removeItem(key);
}

 const getStorageItem = <K extends keyof KeyStorage>(key: K): KeyStorage[K] | null => {
    try{
        return JSON.parse(localStorage.getItem(key)!) as KeyStorage[K];
    } catch(e) {
        console.log('e',e);
        return null;
    }
}

const getCredentials = () => {
    try{
        return JSON.parse(localStorage.getItem("credentials")!) as KeyStorage["credentials"];
    } catch(e) {
        console.log('e',e);
        return null;
    }
}
    return { setStorageItem, getStorageItem, removeStorageItem, getCredentials }
}




// export type KeyOfType<T, K> = { [P in keyof T]: T[P] extends K ? P : string }[keyof T];



