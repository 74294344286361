import * as React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import Navbar from "./components/Navbar/Navbar";
import Main from "./pages/Main/Main";
import socketIOClient, { Socket } from "socket.io-client";
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { BrowserRouter, Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Library from "./pages/Library/Library";
import { useToast } from "@chakra-ui/react";

import { useEffect } from "react";
import { pathSocket } from "./enviroument";
import SignIn from "./pages/SignIn/SignIn";
import { useLocalStorage } from "./hooks/useLocalStorage";
import { extendTheme, type ThemeConfig } from '@chakra-ui/react'
import PlayList from "./pages/PlayList/PlayList";
import { HtmlDecode } from "./utils/htmldecoder";

// 2. Add your color mode config
const config: ThemeConfig = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
}

// 3. extend the theme
const theme = extendTheme({ config })

// Set the configuration for your app
// TODO: Replace with your app's config object
const firebaseConfig = {
  apiKey: "AIzaSyA89ehBcjjRGYAlB3J6D_-wtYY_ZQOgSLQ",
  authDomain: "football-df145.firebaseapp.com",
  projectId: "football-df145",
  storageBucket: "football-df145.appspot.com",
  messagingSenderId: "1080900295763",
  appId: "1:1080900295763:web:6ffa30411989807740faa6",
  measurementId: "G-DGYDW17L8N",
};
export const firebaseApp = initializeApp(firebaseConfig);
export const myStorage = getStorage(firebaseApp);

export let socketConnection: ReturnType<typeof socketIOClient> | null = null;

export const App = () => {

  const { getStorageItem } = useLocalStorage();
  useEffect(() => {
    socketConnection = socketIOClient(pathSocket).connect();
    
    socketConnection && socketConnection.emit("conn", { message: "connected" });
    return () => {
      socketConnection!.disconnect();
    };
  }, []);
  
  return (
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Navbar />
                <Main />
              </>
            }
          />
          <Route
            path="/library"
            element={
              <>
                <Navbar />
                <Library />
              </>
            }
          />
          <Route
            path="/signin"
            element={
              <>
                <SignIn />
              </>
            }
          />
          <Route
            path="/playlist"
            element={
              <>
              <Navbar />
              <PlayList/>
              </>
              
            }
          />
          <Route path="*" element={ <Navigate to={getStorageItem("credentials") ? "/" : "signin"} />}></Route>
          {/* <Footer />  */}
        </Routes>
      </ChakraProvider>
    </BrowserRouter>
  );
};
