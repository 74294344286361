import { ChevronDownIcon, StarIcon } from "@chakra-ui/icons";
import {
  Box,
  Badge,
  Image,
  AspectRatio,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import dayjs from "dayjs";
import React, { FC, useState } from "react";
import { RespYoutubeDownloader } from "../../models/RespYoutubeDownloader";
import { ItemsEntity, RespYoutubeSearch } from "../../models/RespYoutubeSearch";
import youtubedl from "youtube-dl-exec";
import "./Card.scss";
import VideoToAudio from "../../utils/converter";
import useYouTubeDownload from "../../hooks/useYoutubeDownload";
import { ref, getDownloadURL } from "firebase/storage";
import { myStorage } from "../../App";
import DownloadToast from "../DownloadToast/DownloadToast";
import { RespVideoSearch } from "../../models/Resp";
import { usePlayList } from "../../hooks/usePlaylist";
const downloadjs = require('downloadjs');
interface ICard {
  video: RespVideoSearch;
}

const HtmlDecode = (s: string) => {
  var el = document.createElement("div");
  el.innerHTML = s;
  return el.innerText || el.textContent;
}

const Card: FC<ICard> = ({ video }) => {
  const toast = useToast();

  const [downloadInput, setDownloadInput] = useState<string>();
  const [fetchVideo] = useYouTubeDownload();

  const {addFavorite, isVideoInFavorites} = usePlayList();

  const {
    snippet: { publishedAt, title },
    id: { videoId },
  } = video;

  const download = async () => {
    const {
      description,
      snippet: { title, thumbnails },
    } = video;
    const resp = await fetchVideo(video.id.videoId, {
      videoId: video.id.videoId,
      image:
          thumbnails.high?.url || thumbnails.default?.url,
      description,
      title,
    });
    if(!resp) return;

  
    
    
    if(resp.status !== "ok") {
      toast({
        title: 'Download in corso...',
        description: "in Libreria puoi osservare lo stato del processo",
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      return;
    };
      

     const storageRef = ref(myStorage, `mp3/${resp.videoId}.mp3`);
    console.log("🚀 ~ file: Main.tsx ~ line 33 ~ useEffect ~ storageRef", storageRef);

   getDownloadURL(ref(myStorage, `mp3/${resp.videoId}.mp3`))
  .then((url) => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = (event) => {
      const blob = xhr.response;
      
      downloadjs(blob, `${title}.mp3`, "audio/mp3");

    };
    xhr.open('GET', url);
    xhr.send();
  })
  .catch((error) => {
    console.log('error', error);
    
  });
  };


  return (
    // Sample card from Airbnb
    <> 
    {/* <DownloadToast title={title} videoId={videoId} /> */}
  
    <Box
    onClick={() => console.log('id', video.id.videoId)}
      maxW="xxl"
      borderWidth="1px"
      borderRadius="lg"
      marginBottom={"20px"}
      overflow="hidden"
      position={"relative"}
    >
      <AspectRatio maxW="100%" maxH={"350px"} ratio={1}>
        <iframe
          title="naruto"
          src={`https://www.youtube.com/embed/${videoId}`}
          allowFullScreen
        />
      </AspectRatio>

      <Box p="6">
        <Box display="flex" alignItems="baseline">
          {/* <Badge borderRadius="full" px="2" colorScheme="teal">
            New
          </Badge> */}
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            ml="2"
          >
            {/* {property.beds} beds &bull; {property.baths} baths */}
            {publishedAt}
          </Box>
        </Box>

        <Box
          mt="1"
          fontWeight="semibold"
          as="h4"
          lineHeight="tight"
          isTruncated
        >
          {HtmlDecode(title)}
        </Box>
      </Box>
      <Button colorScheme="telegram" marginBottom={"20px"} onClick={download}>
        Download
      </Button>
      <StarIcon onClick={() => addFavorite({videoId, title: HtmlDecode(title)!})} color={isVideoInFavorites(videoId) ? "yellow.500" : "gray"} position={"absolute"} right={5} bottom={5} cursor={"pointer"}></StarIcon>
   
    </Box>
    </>
  );
};

export default Card;
