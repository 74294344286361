import axios from "axios";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { pathApi } from "../enviroument";
import { IRespPlayList } from "../models/RespPlayList";
import { RootReducer } from "../redux";
import {
  addFavoritePlayList,
  setFilteredPlaylist,
  setPlayList,
} from "../redux/reducers/playlist";
import { useLocalStorage } from "./useLocalStorage";

export const usePlayList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { playlist, filteredPlayList } = useSelector(
    ({ playlist }: RootReducer) => playlist
  );
  const { getCredentials } = useLocalStorage();
  const [error, setError] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const fetchPlayList = async () => {
    setisLoading(true);
    try {
      const credentials = getCredentials();
      if (!credentials) {
        navigate("signin");
        return;
      }
      const { token, nickname } = credentials;
      const { data: resp } = await axios.get<IRespPlayList[]>(
        `${pathApi}/youtube/favorites`,
        {
          headers: {
            authorization: `Bearer ${token}`,
            nickname,
          },
        }
      );
      dispatch(setPlayList(resp));
      dispatch(setFilteredPlaylist(resp));
      return resp;
    } catch (e: any) {
      console.log("error", e.response.data.error);

      setError(e.response.data.error);
      return undefined;
    } finally {
      setisLoading(false);
    }
  };

  const filterPlayList = (term: string) => {
    if (!playlist) return;
    if (!term) return dispatch(setFilteredPlaylist(playlist));

    dispatch(
      setFilteredPlaylist(
        playlist!.filter(({ title }) =>
          title.toLowerCase().includes(term.toLowerCase())
        )
      )
    );
  };

  const addFavorite = async (body: Record<"videoId" | "title", string>) => {
    try {
      const credentials = getCredentials();
      if (!credentials) {
        navigate("signin");
        return;
      }
      const { token, nickname } = credentials;
      const { data: resp } = await axios.post<IRespPlayList>(
        `${pathApi}/youtube/addFavorite`,
        body,
        {
          headers: {
            authorization: `Bearer ${token}`,
            nickname,
          },
        }
      );
      console.log('resp', resp);
      
      if (!resp) return;
      dispatch(addFavoritePlayList(resp));
    } catch (e) {
        console.log('e', e);
        
    }
  };

  const isVideoInFavorites = useCallback(
    (videoId: string) => {
      if (playlist === undefined) {
        return false;
      }
      return playlist.some(({ videoId: idVideo }) => idVideo === videoId);
    },
    [playlist]
  );

  return {
    playlist,
    fetchPlayList,
    filterPlayList,
    isLoading,
    error,
    filteredPlayList,
    addFavorite,
    isVideoInFavorites,
  };
};
