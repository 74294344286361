import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IRespYoutubeLibraryMapped } from '../../models/RespYoutubeLibrary'



interface ILibrarySongs {
    songs: IRespYoutubeLibraryMapped[],
}

const initialState = {songs: []} as ILibrarySongs

const librarySongsSlice = createSlice({
  name: 'librarySongs',
  initialState,
  reducers: {

    setLibrarySongs(state, {payload}: PayloadAction<IRespYoutubeLibraryMapped[]>) {
      state.songs = payload
    },
    removeLibrarySong(state, {payload}: PayloadAction<string>) {
      state.songs = [...state.songs.filter(({videoId: videoIdSong}) => payload !== videoIdSong)]
    },
    setPercentualLibrarySong(state, {payload: {videoId, percentual}}: PayloadAction<{videoId: string, percentual: number}>) {
     const findSong =  state.songs.find(({videoId: videoIdSong}) => videoId === videoIdSong);
     findSong!.percentual = percentual;
     state.songs = [...state.songs];
    },
    setStatusLibrarySong(state, {payload: {videoId, status}}: PayloadAction<{videoId: string, status: "ok" | "processing" | "error" | "converting"}>) {
     const findSong =  state.songs.find(({videoId: videoIdSong}) => videoId === videoIdSong);
     if(!findSong) return {...state};
     findSong.status = status;
     state.songs = [...state.songs];
    },
  },
})

export const { setLibrarySongs, removeLibrarySong, setPercentualLibrarySong ,setStatusLibrarySong, } = librarySongsSlice.actions
export default librarySongsSlice.reducer