import { Status } from "@chakra-ui/react";
import axios from "axios"
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { YOUTUTBE_KEY } from "../config";
import { pathApi } from "../enviroument";
import { IRespYoutubeLibrary, IRespYoutubeLibraryMapped } from "../models/RespYoutubeLibrary";
import { RespYoutubeSearch } from "../models/RespYoutubeSearch";
import { RootReducer } from "../redux";
import { setLibrarySongs } from "../redux/reducers/librarySongs";
import { HtmlDecode } from "../utils/htmldecoder";



const useYouTubeDatabase = () => {
    const { songs } = useSelector(({librarySongs}: RootReducer) => librarySongs);
    const dispatch = useDispatch();
    const fetchVideos = async() => {        
        const {data: resp} = await axios.get<IRespYoutubeLibrary>(`${pathApi}/youtube/songs`);
        const mappedResp: IRespYoutubeLibraryMapped[] = resp.map((item) => ({...item, percentual: null})).sort(({status}) => {
           return status === "processing" || status === "converting" ? -1 : 1
        });
        dispatch(setLibrarySongs(mappedResp))
        return resp;
    }

    const filteredData = useCallback((term: string) => {
        if(!songs) return [];
        if(!term) return songs;
        return songs.filter(({title}) => HtmlDecode(title)!.toLowerCase().includes(term.toLowerCase()))
    }, [songs]);


   

    return {songs, filteredData, fetchVideos} as const;

}

export default useYouTubeDatabase