import {
  Box,
  useColorModeValue,
  Flex,
  HStack,
  Heading,
  Link,
  Text,
  color
} from "@chakra-ui/react";
import React, { FC } from "react";
import { ColorModeSwitcher } from "../../ColorModeSwitcher";
import "./Navbar.scss";
import {Link as LinkRouter, useNavigate} from "react-router-dom";
import { useLocalStorage } from "../../hooks/useLocalStorage";

interface INavbar {}

const Navbar: FC<INavbar> = () => {
  const {removeStorageItem, getCredentials} = useLocalStorage();
  const navigate = useNavigate();

  const logout = () => {
    removeStorageItem("credentials")
    navigate("signin");
  }
  return (
    <>
      <Box bg={useColorModeValue("gray.100", "gray.900")} px={4}>
        <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
          {/* <IconButton
          size={'md'}
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          aria-label={'Open Menu'}
          display={{ md: 'none' }}
          onClick={isOpen ? onClose : onOpen}
        /> */}
          <HStack spacing={8} alignItems={"center"}>
            <Heading size="lg" className="special-elite-font">
              <div style={{display: "flex", justifyContent: "center", alignItems: "baseline", flexDirection: "row"}}>
              <Link
                href={`${"#"}`}
                _hover={{ textDecoration: "none", color: "gray.500" }}
              >
                {getCredentials() ? getCredentials()?.nickname : 'Benvenuto'}
              </Link>
              <span onClick={() => navigate("/home")} className="text link__navbar"> <span className="neon">Home</span></span>
              <span onClick={() => navigate("/library")} className="text link__navbar"> <span className="neon">Library</span></span>
              <span onClick={() => navigate("/playlist")} className="text link__navbar"> <span className="neon">Playlist</span></span>
              </div>
              </Heading>





            {/* <HStack
            as={'nav'}
            spacing={4}
            display={{ base: 'none', md: 'flex' }}
          >
            {Links.map((link) => (
              <NavLink key={link}>{link}</NavLink>
            ))}
          </HStack> */}
          </HStack>
          <Flex alignItems={"center"}>
            <ColorModeSwitcher />
            <Text onClick={logout} color={"red.600"} cursor={"pointer"} _hover={{color: "red.900"}}>Log out</Text>
          </Flex>
        </Flex>

        {/* {isOpen ? (
        <Box pb={4} display={{ md: 'none' }}>
          <Stack as={'nav'} spacing={4}>
            {Links.map((link) => (
              <NavLink key={link}>{link}</NavLink>
            ))}
          </Stack>
        </Box>
      ) : null} */}
      </Box>
    </>
  );
};

export default Navbar;
