import {
  Box,
  AspectRatio,
  Button,
  Progress,
  Text,
} from "@chakra-ui/react";
import React, { FC, useEffect, useState } from "react";
import "./CardLibrary.scss";
import { ref, getDownloadURL } from "firebase/storage";
import { myStorage, socketConnection } from "../../App";
import { HtmlDecode } from "../../utils/htmldecoder";
import { useDispatch } from "react-redux";
import { removeLibrarySong, setPercentualLibrarySong, setStatusLibrarySong } from "../../redux/reducers/librarySongs";
import { IRespYoutubeLibraryMapped } from "../../models/RespYoutubeLibrary";
import { StarIcon } from "@chakra-ui/icons";
import { usePlayList } from "../../hooks/usePlaylist";
const downloadjs  = require("downloadjs");
interface ICard {
  video: IRespYoutubeLibraryMapped
}


const CardLibrary: FC<ICard> = ({ video }) => {
  const [isLoading, setisLoading] = useState<boolean>(false);
  const {addFavorite, isVideoInFavorites} = usePlayList();
  const dispatch = useDispatch();
  const {
    title,
    videoId,
    description,
    status,
    percentual
  } = video;

  useEffect(() => {

    socketConnection?.on(`state-download-${video.videoId}`, ({percentual}: {percentual: number}) => {
      dispatch(setStatusLibrarySong({videoId, status: "processing"}))
     dispatch(setPercentualLibrarySong({videoId, percentual}))
          
    })
    socketConnection?.on(`state-status-${video.videoId}`, ({status}: {status: "ok" | "processing" | "error" | "converting"}) => {
     if(status !== "error"){
      dispatch(setStatusLibrarySong({videoId, status}))
     }  else {
      dispatch(removeLibrarySong(videoId));
     }
    })
  }, []);
  const download = async () => {
    setisLoading(true);
    //  const storageRef = ref(myStorage, `mp3/${videoId}.mp3`);

   getDownloadURL(ref(myStorage, `mp3/${videoId}.mp3`))
  .then((url) => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = (event) => {
      const blob = xhr.response;
    downloadjs(blob, `${title}.mp3`, "audio/mp3");
    setisLoading(false);
    };
    xhr.open('GET', url);
    xhr.send();

    //  const a = document.createElement('a');
    //  a.href = url;
    //  a.download = 'prova'; 
    //  a.type = 'audio/mp3'; 
    //  a.innerText = "asd";
    //  a.style.display = "none";
    //     document.body.appendChild(a);
    //     a.click();

  })
  .catch((error) => {
    console.log('error', error);
    setisLoading(false);
  });
  };

  return (
    // Sample card from Airbnb

    <Box
      maxW="xxl"
      borderWidth="1px"
      borderRadius="lg"
      marginBottom={"20px"}
      overflow="hidden"
      position={"relative"}
    >
      <AspectRatio maxW="100%" maxH={"350px"} ratio={1}>
        <iframe
          title="naruto"
          src={`https://www.youtube.com/embed/${videoId}`}
          allowFullScreen
        />
      </AspectRatio>

      <Box p="6">
        <Box display="flex" alignItems="baseline">
          {/* <Badge borderRadius="full" px="2" colorScheme="teal">
            New
          </Badge> */}
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            ml="2"
          >
            {/* {property.beds} beds &bull; {property.baths} baths */}
          </Box>
        </Box>

        <Box
          mt="1"
          fontWeight="semibold"
          as="h4"
          lineHeight="tight"
          isTruncated
        >
          {HtmlDecode(title)}
        </Box>
      </Box>
      <Button colorScheme="telegram" loadingText="downloading..." isLoading={isLoading} disabled={status === "ok" ? false : true} marginBottom={"20px"} onClick={download}>
        Download
      </Button>
      <StarIcon onClick={() => addFavorite({videoId, title: HtmlDecode(title)!})} color={isVideoInFavorites(videoId) ? "yellow.500" : "gray"} position={"absolute"} right={5} bottom={5} cursor={"pointer"}></StarIcon>

      {
      (status === "processing" || status === "converting") &&   
        <Text color='gray.500' fontSize="md" isTruncated>
        {status === "processing" ? 'Downloading...' : "Converting..."}
        </Text> 
      }
     {status === "processing" && (percentual && percentual > 0 && percentual < 100) && <Progress hasStripe value={percentual} /> }
     {status === "converting" &&  <Progress size='xs' isIndeterminate /> }
   
    </Box>
  );
};

export default CardLibrary;
