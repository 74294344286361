import { combineReducers } from "@reduxjs/toolkit";
import  librarySongs  from "./reducers/librarySongs";
import  termSearchInput  from "./reducers/termSearchInput";
import  playlist  from "./reducers/playlist";



export const rootReducer = combineReducers({
    librarySongs,
    termSearchInput,
    playlist
});

export type RootReducer = ReturnType<typeof rootReducer>