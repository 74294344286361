import { Status, useToast } from "@chakra-ui/react";
import axios from "axios"
import { useState } from "react";
import { YOUTUTBE_KEY } from "../config";
import { pathApi } from "../enviroument";
import { RespYoutubeSearch } from "../models/RespYoutubeSearch";

interface IData {
videoId: string,
image: string,
title: string,
description: string,
}

interface IResp {
    id: string,
    videoId: string,
    image: string,
    title: string,
    description: string,
    status: "ok" | "processing",
  
}

const useYouTubeDownload = () => {
    const toast = useToast();
    const fetchSearch = async(videoId: string, data: IData) => {
        try{
            const {data: resp} = await axios.post<IResp>(`${pathApi}/youtube/${videoId}`, {song: data});
            return resp;
            
        } catch(e: any) {
            console.log('resp',e.response);

            toast({
                title: e.response.data.status === "Wait, the list is full" ? "Wait" : 'Error',
                description: (e.response.data.error || e.response.data.status || 'error'),
                status: 'error',
                duration: 5000,
                isClosable: true,
              })
            return undefined;
        }        
        
    }

    return [fetchSearch] as const;

}

export default useYouTubeDownload