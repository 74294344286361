import axios from "axios";
import { useState } from "react";
import { pathApi } from "../enviroument";
import { IRespSignIn } from "../models/RespSignIn";
import { useLocalStorage } from "./useLocalStorage";

export const useSignIn = () => {
  const {setStorageItem} = useLocalStorage();
    const [error, setError] = useState("");
    const [isLoading, setisLoading] = useState(false);

    const fetchSignIn = async (
      credentials: Record<"nickname" | "password", string>
      ) => {
        setisLoading(true);
      try{
          const {data:resp} = await axios.post<IRespSignIn>(`${pathApi}/users/signin`, credentials);
          await setStorageItem("credentials", resp);
          return resp;

      }catch(e: any) {
          console.log('error', e.response.data.error);

        setError(e.response.data.error);
        return undefined
      } finally{
          setisLoading(false);
      }
  };

  return { fetchSignIn, error, isLoading };
};
