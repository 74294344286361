import axios from "axios"
import { useState } from "react";
import { YOUTUTBE_KEY } from "../config";
import { pathApi } from "../enviroument";
import {  RespVideoSearch } from "../models/Resp";


const useYouTubeSearch = () => {
    const [data, setData] = useState<RespVideoSearch[]>();
    const fetchSearch = async(q: string) => {
        try{
        const {data: resp} = await axios.get<RespVideoSearch[]>(`${pathApi}/youtube/`, {params: {
            term: q
        }});
        
        setData(resp);
    }catch(e) {
        console.log('e',e);
        return undefined;
    }
    }

    return [data, fetchSearch] as const;

}

export default useYouTubeSearch