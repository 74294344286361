import {
    Box,
    AspectRatio,
    Button,
    Progress,
    Text,
  } from "@chakra-ui/react";
  import React, { FC, useEffect, useState } from "react";
  import "./CardPlayList.scss";
  import { ref, getDownloadURL } from "firebase/storage";
  import { myStorage, socketConnection } from "../../App";
  import { HtmlDecode } from "../../utils/htmldecoder";
  import { useDispatch } from "react-redux";
  import { removeLibrarySong } from "../../redux/reducers/librarySongs";
import { IRespPlayList } from "../../models/RespPlayList";
import { setStatusPlaylistSong } from "../../redux/reducers/playlist";
const downloadjs  = require("downloadjs");


  interface ICard {
    video: IRespPlayList
  }
  
  
  const CardPlayList: FC<ICard> = ({ video }) => {
    const [isLoading, setisLoading] = useState<boolean>(false)
    const dispatch = useDispatch();
    const {
      title,
      videoId,
      percentual, 
      status
    } = video;
  
    useEffect(() => {
  
      socketConnection?.on(`state-download-${videoId}`, ({percentual}: {percentual: number}) => {
        dispatch(setStatusPlaylistSong({videoIdSong: videoId, status: "processing", percentual}))
        
        
      })
      socketConnection?.on(`state-status-${videoId}`, ({status}: {status: "ok" | "processing" | "error" | "converting"}) => {
       if(status !== "error"){
        dispatch(setStatusPlaylistSong({videoIdSong: videoId, status}))

       }  else {
        dispatch(removeLibrarySong(videoId));
       }
      })
    }, []);

    
    
    const download = async () => {
        setisLoading(true);
    //    const storageRef = ref(myStorage, `mp3/${videoId}.mp3`);
  
     getDownloadURL(ref(myStorage, `mp3/${videoId}.mp3`))
    .then((url) => {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = (event) => {
        const blob = xhr.response;
        downloadjs(blob, `${HtmlDecode(title)}.mp3`, "audio/mp3");
        setisLoading(false);
      };
      xhr.open('GET', url);
      xhr.send();
    })
    .catch((error) => {
      console.log('error', error);
      setisLoading(false);

    });
    };
  
  
    return (
      // Sample card from Airbnb
  
      <Box
        h="xs"
        w={"sm"}
        marginRight={"2"}
        marginLeft={"2"}
        borderWidth="1px"
        borderRadius="lg"
        marginBottom={"20px"}
        overflow="hidden"
      >
        <AspectRatio maxW="100%" maxH={"148px"} ratio={1}>
          <iframe
            title="naruto"
            src={`https://www.youtube.com/embed/${videoId}`}
            allowFullScreen
          />
        </AspectRatio>
  
        <Box p="6">
          <Box display="flex" alignItems="baseline">
            {/* <Badge borderRadius="full" px="2" colorScheme="teal">
              New
            </Badge> */}
            <Box
              color="gray.500"
              fontWeight="semibold"
              letterSpacing="wide"
              fontSize="xs"
              textTransform="uppercase"
              ml="2"
            >
              {/* {property.beds} beds &bull; {property.baths} baths */}
            </Box>
          </Box>
  
          <Box
            mt="1"
            fontWeight="semibold"
            as="h4"
            lineHeight="tight"
            isTruncated
          >
            {HtmlDecode(title)}
          </Box>
        </Box>
        <Button colorScheme="telegram" 
        loadingText="downloading..." isLoading={isLoading} 
        // disabled={status === "ok" ? false : true} 
        marginBottom={"20px"} onClick={download}>
          Download
        </Button>
        {
        (status === "processing" || status === "converting") &&   
          <Text color='gray.500' fontSize="md" isTruncated>
          {status === "processing" ? 'Downloading...' : "Converting..."}
          </Text> 
        }
       {status === "processing" && (percentual && percentual > 0 && percentual < 100) && <Progress hasStripe size={"xs"} value={percentual} /> }
       {status === "converting" &&  <Progress size='xs' isIndeterminate /> }
     
      </Box>
    );
  };
  
  export default CardPlayList;
  