import { createSlice, PayloadAction } from '@reduxjs/toolkit'



interface ITermSearchInput {
    term: string,
}

const initialState = {term: ""} as ITermSearchInput

const termSearchInputSlice = createSlice({
  name: 'termSearchInput',
  initialState,
  reducers: {

    setTermSearchInput(state, {payload}: PayloadAction<string>) {
      state.term = payload
    },
  },
})

export const { setTermSearchInput  } = termSearchInputSlice.actions
export default termSearchInputSlice.reducer