import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IRespPlayList } from '../../models/RespPlayList'



interface IPlayList {
    playlist: (IRespPlayList & Partial<{status: string, percentual: null | number}>)[] | undefined,
    filteredPlayList: (IRespPlayList & Partial<{status: string, percentual: null | number}>)[] | undefined,
}

const initialState = {playlist: undefined, filteredPlayList: undefined} as IPlayList

const playlistSlice = createSlice({
  name: 'termSearchInput',
  initialState,
  reducers: {

    setPlayList(state, {payload}: PayloadAction<IRespPlayList[]>) {
      state.playlist = payload.map((item) => ({...item, percentual: null, status: "" }))
    },
    addFavoritePlayList(state, {payload}: PayloadAction<IRespPlayList>) {
        state.playlist && (state.playlist = state.playlist.some(({videoId}) => payload.videoId === videoId) ? state.playlist.filter(({videoId}) => videoId !== payload.videoId) :  [...state.playlist, payload]);
    },
    setStatusPlaylistSong(state, {payload: {videoIdSong, status, percentual}}: PayloadAction<{videoIdSong: string, status: string, percentual?: number}>) {
     const song =  state.playlist!.find(({videoId}) => videoId === videoIdSong);
     
    song && (song.status = status);
    song && percentual && (song.percentual = percentual);
     const songFiltered =  state.filteredPlayList!.find(({videoId}) => videoId === videoIdSong);
     
    songFiltered && (songFiltered.status = status);
    songFiltered && percentual && (songFiltered.percentual = percentual);
    state.filteredPlayList = state.filteredPlayList!.sort(({status}) => {
        return status === "processing" || status === "converting" ? -1 : 1
     });
    state.playlist = state.playlist!.sort(({status}) => {
        return status === "processing" || status === "converting" ? -1 : 1
     });
     state = {...state}
    },
    setFilteredPlaylist(state, {payload}: PayloadAction<IRespPlayList[]>) {
      state.filteredPlayList = payload
    },
  },
})

export const { setPlayList, setFilteredPlaylist, setStatusPlaylistSong, addFavoritePlayList  } = playlistSlice.actions
export default playlistSlice.reducer